.col-md-1 {
    -ms-flex-preferred-size: 5.33333333%;
    flex-basis: 5.33333333%;
    max-width: 5.33333333%;
}

@media only screen and (min-width: 1200px) {
    .container {
        width: 100%;
        max-width: 100%;
    }
}

.row {
    flex-wrap: nowrap;
}